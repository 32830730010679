exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-creations-js": () => import("./../../../src/pages/creations.js" /* webpackChunkName: "component---src-pages-creations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reads-js": () => import("./../../../src/pages/reads.js" /* webpackChunkName: "component---src-pages-reads-js" */),
  "component---src-pages-writings-js": () => import("./../../../src/pages/writings.js" /* webpackChunkName: "component---src-pages-writings-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

